import Vue from 'vue'

Vue.mixin({
    methods: {
        findArrayIndex(array, col, wert, col2, wert2) {

          let lReturn = -1;
      
          for (let i = 0; i < array.length; i++) {

            console.log("tes1t")
      
              if (col2 && wert2) {
                  if (array[i][col] && array[i][col] === wert && array[i][col2] && array[i][col2] === wert2) {
                      lReturn = i;
                      break;
                  }
              } else {
                  if (array[i][col] && array[i][col] === wert) {
                      lReturn = i;
                      break;
                  }
              }
          }
          return lReturn;
        },
        Alerta(){
            alert('WORK!')
        },
        copyObject(item) {
            return JSON.parse(JSON.stringify(item))
        },
        layoutColor(setting){
            
            if (this.$vuetify.theme.defaults.settings[setting] && this.$vuetify.theme.defaults.settings[setting].color) {
              return this.$vuetify.theme.defaults.settings[setting].color
            }
            if (this.$vuetify.theme.currentTheme.settings[setting] && this.$vuetify.theme.currentTheme.settings[setting].color) {
              return this.$vuetify.theme.currentTheme.settings[setting].color
            }
            return;
          },
        layoutLight(setting){
            if (this.$vuetify.theme.defaults.settings[setting] && this.$vuetify.theme.defaults.settings[setting].light) {
              return this.$vuetify.theme.defaults.settings[setting].light
            }
            if (this.$vuetify.theme.currentTheme.settings[setting] && this.$vuetify.theme.currentTheme.settings[setting].light) {
              return this.$vuetify.theme.currentTheme.settings[setting].light
            }
            return;
          },
        layoutDark(setting){
            if (this.$vuetify.theme.currentTheme.settings[setting] && this.$vuetify.theme.currentTheme.settings[setting].dark) {
              return this.$vuetify.theme.currentTheme.settings[setting].dark
            }
            return false;
        },  
        attributAnzeigen(item, array) {
          if (item.ifwlattr) {
            for (let i = 0; i < array.length; i++) {
              let attribut = array[i];
    
              if (attribut.attributart === 9  &&  attribut.wlattr && attribut.wlattr === item.ifwlattr) {
                return true
              }
              if (attribut.attributart === 4  && attribut.id ===  item.ifwlattr &&  attribut.marktiert) {
                return true
              }
            }
            return false
          }
          return true
      },      
        
    }
})