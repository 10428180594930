

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";
import store from "./store";
import './mixins/generalMixins'
import { CHECK_AUTH } from "./store/actions.type";
import ApiService from "./common/api.service";
import ErrorFilter from "./common/error.filter";
import './registerServiceWorker'
import { longClickDirective } from 'vue-long-click'
import VueMatomo from 'vue-matomo'



export const bus = new Vue();


Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.cidb.de/',
  siteId: 2,
 
  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',
 
  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',
 
  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',
 
  // Enables automatically registering pageviews on the router
  router: router,
 
  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,
 
  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,
 
  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,
 
  // Run Matomo without cookies
  // Default: false
  disableCookies: false,
 
  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: false,
 
  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,
 
  // Whether or not to log debug information
  // Default: false
  debug: false,
 
  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,
 
  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,
 
  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: undefined,
 
  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: []
});



//import './styles/App.domadoc.scss';
import("./styles/App." + process.env.VUE_APP_PRODUKT + ".scss");


if (!checkBrowser()) {
  var element = document.getElementById("outdated-browser");
  element.classList.add("ci-show");
}else {


Vue.filter("error", ErrorFilter);



const longClickInstance = longClickDirective({delay: 400, interval: 50})
Vue.directive('longclick', longClickInstance)


Vue.config.productionTip = false
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(store.state.auth.isAuthenticated){
      next()
      return
    }
    next('/login')
    return
  }
  next()
});

// Check


Promise.all([store.dispatch(CHECK_AUTH)]).then(() => {
  initializeVue(() => {})
}).catch(() => {
  initializeVue(() => {
    alert("Sie wurden abgemeldet!")
  })
});



}





function initializeVue(back)
{
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created: back()
}).$mount('#app')
}

function checkBrowser() {
  let browserObj = browser()

  if (browserObj.isEdge) {
    if (browserObj.meta.version >= 18) {
      return true
    }
  }

  if (browserObj.isChrome || browserObj.isChromeIOS) {
    if (browserObj.meta.version >= 80) {
      return true
    }
  }

  if (browserObj.isNewIpad) {
    return true
  }

  if (browserObj.isFirefox) {
    if (browserObj.meta.version >= 74) {
      return true
    }
  }

  if (browserObj.isSafari) {
    alert(browserObj.meta.version);

    if (browserObj.meta.version >= 11) {
      alert(browserObj.meta.version);

      return true
    }
  }

  if (browserObj.isIOS) {
    if (browserObj.meta.version >= 11) {
      return true
    }
  }

  return false;
}



function browser() {
 
let ua = window.navigator.userAgent;
let browserObj = {};

// Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
browserObj.isOpera =
  !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
// Firefox 1.0+
browserObj.isEdge = /Edge/.test(navigator.userAgent);
browserObj.isFirefox = /Firefox/.test(navigator.userAgent);
// Safari 3.0+
/*eslint-disable */
browserObj.isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function(p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || safari.pushNotification);
/*eslint-ensable */
// Internet Explorer 6-11
browserObj.isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+

browserObj.isChrome = /Google Inc/.test(navigator.vendor);
browserObj.isChromeIOS = /CriOS/.test(navigator.userAgent);
browserObj.isIOS =
   /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

browserObj.isNewIpad = /Macintosh; Intel Mac OS/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent) && navigator.maxTouchPoints > 1 && !window.MSStream;

browserObj.meta = browserSpecs(ua);
browserObj.meta.ua = ua;
return browserObj;
}




function browserSpecs(ua) {
  /**
   * https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
   */
  var tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return { name: M[0], version: M[1] };
}
