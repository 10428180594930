import Vue from "vue";
import Router from "vue-router";



Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/rolle/:roll/id/:p_id/start/:strt/menue/:pmen/funktion/:pfkt/piwa/:piwa",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/start/:strt/funktion/:pfkt/piwa/:piwa",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/start/:strt/menue/:pmen/funktion/:pfkt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/pfad/:pfad/menue/:pmen/funktion/:pfkt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/menue/:pmen/funktion/:pfkt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },        
    {
      path: "/rolle/:roll/id/:p_id/start/:strt/funktion/:pfkt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/pfad/:pfad/funktion/:pfkt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/start/:strt/menue/:pmen",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/pfad/:pfad/menue/:pmen",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },                
    {
      path: "/rolle/:roll/id/:p_id/funktion/:pfkt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/menue/:pmen",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/start/:strt",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id/pfad/:pfad",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll/id/:p_id",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/rolle/:roll",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      path: "/id/:p_id",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      name: "home",
      path: "/",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/Home"),
    },
    {
      name: "neuesKennwort",
      path: "/kennwort/:email/:token",
      meta: { 
        kennwortVersenden: true
      },
      component: () => import("@/views/Login")
    },    
    {
      name: "login",
      path: "/login",
      meta: {
        analyticsIgnore: true
      },
      component: () => import("@/views/Login")
    },    
    {
      name: "kennwort",
      path: "/kennwort",
      meta: { 
        requiresAuth: true
      },    
      component: () => import("@/views/Kennwort")
    },    
    {
      name: "kennwort-vergessen",
      path: "/kennwort-vergessen",      
      component: () => import("@/views/KennwortVergessen")
    },    
    {
      name: "schema-editor",
      path: "/schema-editor",      
      component: () => import("@/views/HomeTest")
    },    
    {
      name: "NotFound",
      path: "*",
      meta: { 
        requiresAuth: true
      },
      component: () => import("@/views/NotFound"),
    }
  ]
});