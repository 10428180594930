<template>
<div class="ci-main-frame" v-bind:class="{'ci-theme--light': !theme.isDark, 'ci-theme--dark': theme.isDark}">
  <div class="ci-main-container"> 
    <router-view></router-view>
  </div>
</div>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
 data() {
    return {
      theme: this.$vuetify.theme,
    };
  },
  created: function () {
    /*
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolved) {
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          store.commit(PURGE_AUTH);
        }
        throw err;
      });
    });
    */
  }
};
</script>
