import { PageService } from "@/common/api.service";
import ApiService from "../common/api.service";
import router from '../router'
import { FETCH_PAGE, POST_ATTRIBUT, POST_ATTRIBUTE, POST_SCHEMA_ELEMENTE, SUCHBUTTON, GET_BLOB, NAVI_PAGE, GET_NAVI_LINK, POST_SEARCH } from "./actions.type";
import {
  FETCH_START,
  FETCH_END,
  SET_DATEN,
  SET_ALERT_RESPONSE,
  SET_SELECTED_MENUE,
  RELAOD_ATTRIBUTE,
  SET_CONFIRM,
  RESET_SEARCH,
  SET_SELEMENT_SVG_WIDTH,
  SET_SELEMENT_SVG_HEIGHT
} from "./mutations.type";




const state = {
  daten: {},
  attribute: [],
  selectedMenue: null,
  isLoading: false,
  isRequesting: false,
  loaderVerzoegert: false,
  zeigeSuchfelder: false,
  suchItem: null,
  suchFormular: null,
  editing: false,
  suchSeiten: null,
  seiten: [],
  selectedSeite: 0,
  elementSVGWidth: 0,
  elementSVGHeight: 0

};


const getters = {
  daten(state) {
    return state.daten;
  },
  attribute(state) {
    return state.attribute;
  },  
  suchItem(state) {
    return state.suchItem;
  },
  suchFormular(state) {
    return state.suchFormular;
  },
  editing(state) {
    return state.editing;
  },  
  selectedMenue(state) {
    return state.selectedMenue;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isRequesting(state) {
    return state.isRequesting;
  },
  zeigeSuchfelder(state) {
    return state.zeigeSuchfelder;
  },
  selectedSeite(state) {
    return state.selectedSeite;
  },
  elementSVGWidth(state) {
    return state.elementSVGWidth;
  },
  elementSVGHeight(state) {
    return state.elementSVGHeight;
  }
 
};

const setters = {
  editing(state, editing) {
    state.editing = editing
  },  
  suchFormular(state, suchFormular) {
    state.suchFormular = suchFormular
  },
  elementSVGWidth(state, elementSVGWidth) {
    state.elementSVGWidth = elementSVGWidth
  }
}


function findArrayIndex(array, col, wert, col2, wert2) {

  let lReturn = -1;

  for (let i = 0; i < array.length; i++) {

      if (col2 && col2 != undefined && wert2 && wert2 != undefined) {
          if (array[i][col] && array[i][col] === wert && array[i][col2] && array[i][col2] === wert2) {
              lReturn = i;
              break;
          }
      } else {
          if (array[i][col] && array[i][col] === wert) {
              lReturn = i;
              break;
          }
      }
  }
  return lReturn;
}

function NaviLink(navi){
  let naviString = ""

  if (navi === undefined) {
      return naviString;
  }

  if (navi.roll === undefined) {
    navi.roll = 100;
  }

  if (navi.roll !== undefined) {
      naviString = '/rolle/' + navi.roll;

      if (navi.p_id  !== undefined) {
          naviString = naviString + '/id/' + navi.p_id;
      }

      if (navi.strt  !== undefined) {
          naviString = naviString + '/start/' + navi.strt;
      }

      if (navi.pfad  !== undefined) {
          naviString = naviString + '/pfad/' + navi.pfad;
      }


      if (navi.pmen !== undefined) {
          naviString = naviString + '/menue/' + navi.pmen;
      }

      if (navi.pfkt !== undefined) {
          naviString = naviString + '/funktion/' + navi.pfkt;
      }

      if (navi.piwa !== undefined) {
          naviString = naviString + '/piwa/' + navi.piwa;
      }

      
  } else {
      naviString = '/';
  }

  return naviString
}


const actions = {
  [FETCH_PAGE]({ commit }, navi) {
    
    commit(FETCH_START);

    let suchFormular = null
    if (state.suchSeiten && navi && navi.p_id &&  state.suchSeiten[navi.p_id] && state.suchSeiten[navi.p_id][(navi.pmen)? navi.pmen : 0]) {
        suchFormular =  state.suchSeiten[navi.p_id][(navi.pmen)? navi.pmen : 0];
        Object.assign( navi, suchFormular)
    }

    return PageService.query(navi)
      .then(({ data }) => {
        commit(FETCH_END);

        if (data.daten && data.daten.folgeseite) {
          router.push(NaviLink(data.daten.folgeseite.navi));
          return;
        }

        if (suchFormular) {
          state.suchFormular = suchFormular;
        }else {
          state.suchFormular = null
        }
     

        commit(SET_DATEN, data.daten);
      })
      .catch(({ response }) => {
        commit(FETCH_END);
        commit(SET_ALERT_RESPONSE, response);
      });
  },
  [POST_ATTRIBUTE]({ commit }, navi) {
    commit(FETCH_START);
    let params = {
      navi: navi,
      attribute: {}
    }

    if (state.daten && state.daten.einzelsatz) {
      if (state.daten.einzelsatz.update) {
        state.attribute.forEach(attribut => {
     
          if (attribut.update) {
            if (attribut.value && attribut.prefix &&  attribut.prefix.length) {
              attribut.prefix.forEach(item => {
                if (item.checked) {
                  attribut.value = item.text + attribut.value
                }
              });
            }
            
            params.attribute[attribut.id] = attribut.value
          }
        });
      }
    }
    return PageService.post(params)
      .then(({ data }) => {
        commit(FETCH_END);

        if (data.daten && data.daten.folgeseite) {
          router.push(NaviLink(data.daten.folgeseite.navi));
        }

      })
      .catch(({ response }) => {
        commit(FETCH_END);
        commit(SET_ALERT_RESPONSE, response);
      });

  },
  [POST_ATTRIBUT]({ commit }, params) {
    /*
    commit(FETCH_START);

    if (state.daten && state.daten.einzelsatz) {
      if (state.daten.einzelsatz.update) {
        state.daten.einzelsatz.attribute.forEach(attribut => {
     
          if (attribut.update) {
            if (attribut.value && attribut.prefix &&  attribut.prefix.length) {
              attribut.prefix.forEach(item => {
                if (item.checked) {
                  attribut.value = item.text + attribut.value
                }
              });
            }
            
            params.attribute[attribut.id] = attribut.value
          }
        });
      }
    }
    return PageService.post(params)
      .then(({ data }) => {
        commit(FETCH_END);

        if (data.daten && data.daten.folgeseite) {
          router.push(NaviLink(data.daten.folgeseite.navi));
        }

      })
      .catch(({ response }) => {
        commit(FETCH_END);
        commit(SET_ALERT_RESPONSE, response);
      });
      */

  



     return PageService.post(params)
     .then(({ data }) => {
       commit(FETCH_END);  
       return data;
     })
     .catch(({ response }) => {
       commit(FETCH_END);
       commit(SET_ALERT_RESPONSE, response);
       return false;
     });
  },
  [POST_SCHEMA_ELEMENTE]({ commit }, params) {
     commit(FETCH_START);
     return PageService.post(params)
     .then(({ data }) => {
       commit(FETCH_END);  
       return data;
     })
     .catch(({ response }) => {
       commit(FETCH_END);
       commit(SET_ALERT_RESPONSE, response);
       return false;
     });
  },  
  [NAVI_PAGE]({ commit }, item) {

    if (!item.navi) {
      return
    }

    let confirm = false;
    let confrimMessage = null;
    let confirmOK = "OK"
    let confirmCancle = "Abbrechen"

    //console.log(this)

    if (item.confirm) {
      confirm = true
      confrimMessage = item.confirm
    } else if (!item.submit) {
      if (state.daten && state.daten.einzelsatz && state.daten.einzelsatz.update) {
        confirm = true
        confrimMessage = "Alle Eingaben gehen verloren. Wollen Sie wirklich abbrechen?"
        confirmOK = "Ja"
        confirmCancle = "Nein"
      }
    } 

    if (confirm) {
      commit(SET_CONFIRM, {
        message: confrimMessage,      
        ok: confirmOK,
        cancle: confirmCancle,  
        link: NaviLink(item.navi)
      });
      return
    }

    router.push(NaviLink(item.navi));
  },
  [GET_NAVI_LINK]({ commit }, navi) {
    if (commit){
      return NaviLink(navi)
    }
    return
 
  },  

  [GET_BLOB]({ commit }, item) {
    commit(FETCH_START);

    return PageService.file(item.navi)
      .then(({ data }) => {
        commit(FETCH_END);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.blobname);
        document.body.appendChild(link);
        link.click();

      }).catch(({ response }) => {
        commit(FETCH_END);
        commit(SET_ALERT_RESPONSE, response);
      });

  },
  // eslint-disable-next-line
  [SUCHBUTTON](context, item) {
    if (state.zeigeSuchfelder) {
      state.zeigeSuchfelder = false
      state.suchFormular = null
      let navi = item.navi;
      if (state.suchSeiten && navi && state.suchSeiten[navi.p_id] && state.suchSeiten[navi.p_id][(navi.pmen)? navi.pmen : 0]){

        delete  state.suchSeiten[navi.p_id][(navi.pmen)? navi.pmen : 0]
      }
      context.dispatch(FETCH_PAGE, navi)

    } else {
      context.commit(RESET_SEARCH)
      state.zeigeSuchfelder = true

    }    
  },
  [POST_SEARCH](context) {
    if (!state.suchSeiten) {
      state.suchSeiten = {}
    }

    let navi = state.suchItem.navi


    if ( state.suchSeiten[navi.p_id]  === undefined) {
      state.suchSeiten[navi.p_id] = {}    
    }

    state.suchSeiten[navi.p_id][(navi.pmen)? navi.pmen : 0] = state.suchFormular;


    context.dispatch(FETCH_PAGE, navi)
  },
  ["TEST_FETCH_SCHEMA_EDITOR"]({ commit }) {
    
    commit(FETCH_START);

    let suchFormular = null

    return ApiService.query('http://domadoc.com:8080/dada/TEST_SCHEMA_EDITOR')
      .then(({ data }) => {
        commit(FETCH_END);

        if (data.daten && data.daten.folgeseite) {
          router.push(NaviLink(data.daten.folgeseite.navi));
          return;
        }

        if (suchFormular) {
          state.suchFormular = suchFormular;
        }else {
          state.suchFormular = null
        }
  

        commit("TEST_SET_SCHEMA_EDITOR", data.daten);
      })
      .catch(({ response }) => {
        commit(FETCH_END);
        commit(SET_ALERT_RESPONSE, response);
      });
  },
};


function reloadAttribute(state) {
  var attribute = []
    state.daten.einzelsatz.attribute.forEach(item => {
      if (item.ifwlattr) {
        for (let i = 0; i <  state.attribute.length; i++) {
          let attribut =  state.attribute[i];

          if (attribut.attributart === 9  &&  attribut.wlattr && attribut.value == item.ifwlattr) {
            attribute.push(item)
              break;
          }
          if (attribut.attributart === 4  && attribut.id ===  item.ifwlattr &&  attribut.marktiert) {
              attribute.push(item)
              break;
          }
        }
      }else {
        attribute.push(item)
      }
    });

    state.attribute = attribute
}


/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [FETCH_START](state) {
    state.isRequesting = true;
    if (state.loaderVerzoegert) {
      clearTimeout(state.loaderVerzoegert);
    }

    state.loaderVerzoegert = setTimeout(() => {
      if (state.isRequesting) {
        state.isLoading = true;
      }
    }, 200);
  },
  [FETCH_END](state) {
    clearTimeout(state.loaderVerzoegert);
    //state.daten = null;
    state.isRequesting = false;
    state.isLoading = false;

  },
  [RELAOD_ATTRIBUTE](state) {
    reloadAttribute(state)

  },  
  [RESET_SEARCH](state) {
    if (!state.suchFormular) {
      state.suchFormular = {}
    } 
    state.suchFormular = {...""}

    /*
    state.suchFormular["sfld"] = ""
    state.suchFormular["svon"] = ""
    state.suchFormular["sbis"] = ""
    state.suchFormular["stat"] = 0
    */

    if (state && state.suchItem && state.suchItem.statuswerte  && state.suchItem.statuswerte.length &&  state.suchItem.statuswerte.length > 0) {
      state.suchItem.statuswerte.forEach((element) => {
        if (element.default) {
          state.suchFormular.stat = element.id;
        }
      });

    }

  },  
  [SET_DATEN](state, daten) {


    let kontextID = -1;
    if (daten && daten.kontext && daten.kontext.id) {
      kontextID = daten.kontext.id;
    }

    if (daten.einzelsatz && daten.einzelsatz && daten.einzelsatz.attribute) {

      daten.einzelsatz.attribute.forEach(attribut => {
        attribut.value = attribut.text || attribut.zahl || attribut.datum || attribut.wlattr || attribut.markiert
        
        // ueberpruefen, ob an checkbox oder werteliste eine abhaengigkeit dranhaengt
        if (daten.einzelsatz.update) {
          if (attribut.ifwlattr) {
            for(var i = 0; i<daten.einzelsatz.attribute.length; ++i) {
              var ifattribut = daten.einzelsatz.attribute[i];

              if (ifattribut.attributart == 18 || ifattribut.attributart == 4) {
                if (ifattribut.id == attribut.ifwlattr) {
                  if (ifattribut.attributart == 4){
                    ifattribut.reloadForm = true;
                  }else {
                    var idx = findArrayIndex(daten.einzelsatz.attribute, "id", ifattribut.oben)
                    if (idx && idx > 0) {
                      daten.einzelsatz.attribute[idx].reloadForm = true;
                    }
                  }
                  break;
                }
              }
            }
          }
        }

      });

      state.attribute =  daten.einzelsatz.attribute;

    }

    state.daten = daten;


    if (state.daten && state.daten.einzelsatz && state.daten.einzelsatz.update) {
      reloadAttribute(state)
    }

    state.suchItem = null;
    state.zeigeSuchfelder = false

    // falls page direkt aufgerufen wurden und pfad array leer ist
    if (daten.pfad && state.seiten.length == 0) {
      daten.pfad.forEach(pfad => {
        state.seiten.push(pfad.id)
      });
    }

    if (daten.kontext && daten.kontext.id) {
      // pfad hinzufuegen
      state.seiten.push(daten.kontext.id)
    }

    let i = 0
    const seiten = []
    state.selectedSeite = 0

    for (i; i < state.seiten.length; ++i) {
      seiten.push(state.seiten[i]);
      if (state.seiten[i] === kontextID) {
        break
     }
    }

    if (daten.liste) {
      if (state.seiten[i+1] !== undefined && state.seiten[i+1] !==  kontextID) {
        state.selectedSeite = state.seiten[i+1] ;
      }
    }
    state.seiten = seiten;

    // Suchfilter anzeigen/ausblenden
    if (daten && daten.aktionsmenue) {
      daten.aktionsmenue.forEach(menue => {
        if (menue.search){
          state.suchItem = menue
            if (state.suchFormular) {
              state.zeigeSuchfelder = true
            }
            return
          }
      });
    }

    // Tabreiter aktivieren
    if (daten.kontext && !daten.kontext.navi) {
      state.selectedMenue = 0;
    } else {
      if (daten.menue) {
        daten.menue.forEach((item, idx) => {
          if (!item.navi) {
            state.selectedMenue = idx + 1;
            return
          }
        });
      }
    }
  }, 
  ["TEST_SET_SCHEMA_EDITOR"](state, daten) {
    let kontextID = -1;
    if (daten && daten.kontext && daten.kontext.id) {
      kontextID = daten.kontext.id;
    }


    state.daten = daten;
    state.suchItem = null;
    state.zeigeSuchfelder = false

    // falls page direkt aufgerufen wurden und pfad array leer ist
    if (daten.pfad && state.seiten.length == 0) {
      daten.pfad.forEach(pfad => {
        state.seiten.push(pfad.id)
      });
    }

    if (daten.kontext && daten.kontext.id) {
      // pfad hinzufuegen
      state.seiten.push(daten.kontext.id)
    }

    let i = 0
    const seiten = []
    state.selectedSeite = 0

    for (i; i < state.seiten.length; ++i) {
      seiten.push(state.seiten[i]);
      if (state.seiten[i] === kontextID) {
        break
     }
    }

    state.seiten = seiten;

    /*
    if (daten.schemaeditor) {
      
    }
    */

  },

  [SET_SELECTED_MENUE](state, select) {
    state.selectedMenue = select
  },
  [SET_SELEMENT_SVG_WIDTH](state, width) {
    state.elementSVGWidth = width
  },
  [SET_SELEMENT_SVG_HEIGHT](state, height) {
    state.elementSVGHeight = height
  }   
};

export default {
  state,
  getters,
  setters,
  actions,
  mutations
};