import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  setHeader() {
    if (JwtService.getToken()) {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Token ${JwtService.getToken()}`;
    } else {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = null;
    }

  },

  resetHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = null;
  },

  query(resource, params = "") {
    return Vue.axios.get(`${resource}`, {
      params: params
    })
  },
  file(resource, params = "") {    
    return Vue.axios.get(`${resource}`, {
      params: params,
      responseType: 'blob'
    })
    
  },



  externQuery(resource, params = "") {
    return Vue.axios.get(`${resource}`, {
      params: params
    })
  },
  

  upload(resource, params, formular = "") {    
 

    return Vue.axios.post(`${resource}`, 
        formular, 
        {
          params: params,
          headers: {
            'Content-Type': 'multipart/form-data'
        }
 
    })
    
  },    

  /*
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
    
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
*/
  
  get(resource, slug = "") {
   /* string =  new HttpParams({ fromObject: slug });
    console.log('TEST1', string);*/
    return Vue.axios.get(`${resource}/${slug}`);
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource);
  }
};

export default ApiService;

export const PageService = {
  rotate(params) {
    return ApiService.query("rotate", params);
  },
  query(params) {
    return ApiService.query("query", params);
  },
  file(params) {
    return ApiService.file("query", params);
  }, 
  upload(params, formular) {
    return ApiService.upload("upload", params, formular);
  },     
  post(params) {
    return ApiService.post("query", params);
  },
  get(slug) {
    return ApiService.get("query", slug);
  },
  create(params) {
    return ApiService.post("query", { article: params });
  },
  update(slug, params) {
    return ApiService.update("query", slug, { article: params });
  },
  destroy(slug) {
    return ApiService.delete(`query/${slug}`);
  }
};
