import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

let mainColor =  "indigo";

const vuetify =  new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
    themes: {
      settings: {
        header: {
     
        },  
        header_menue: {
     
        },  
      },
      light: {        
        settings: {
          header: {
            color: mainColor ,
            dark: true
          },
          header_menue: {
            color:  mainColor + ' lighten-4',
            light: true
          },  
          header_progressbar: {
            color:  mainColor + ' lighten-3',
            light: true
          },  
        }, 
        primary: colors[mainColor].darken4,
        secondary: colors[mainColor].darken2,
        accent: colors.shades.black,
        error: colors.red.accent3,        
      },
      dark: {
        settings: {
          header: {
            dark: false,
          },
          header_menue: {
            dark: false,
          },  
        },       
        primary: colors[mainColor].lighten3,
      },
    },
  } 
});


export default vuetify