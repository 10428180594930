export const LOGIN = "login";
export const LOGOUT = "logout";
export const KENNWORT = "kennwort";
export const KENNWORT_VERGESSEN = "kennwortVergessen";
export const KENNWORT_ANFORDERN = "kennwortAnfordern";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHECK_AUTH = "checkAuth";
export const FETCH_PAGE = "fetchPage";
export const CLEAR_ALERT = "clearAlert";
export const POST_ATTRIBUT = "postAttribut";
export const POST_ATTRIBUTE = "postAttribute";
export const GET_BLOB = "getBlob";

export const CONFIRM_ALERT = "confirmAlert";
export const SUCHBUTTON = "clickSuchbutton";
export const NAVI_PAGE = "naviPage";
export const GET_NAVI_LINK = "getNaviLink";
export const POST_SEARCH = "postSearch";
export const POST_SCHEMA_ELEMENTE = "postSchemaAttribute";






