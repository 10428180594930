
import { SET_ALERT_RESPONSE,SET_ALERT,SET_ALERT_RESET, SET_CONFIRM, SET_ALERT_LOGOUT } from "./mutations.type";


const state = {
    message: null,
    login: null,
    confirmLink: null,
    confirmPageParam: null,
    ok: null,
    cancle: null,

    
};

const actions = {
    /*
    [CLEAR_ALERT](context) {
        return new Promise((resolve) => {
            let retLogin = false;
            if ( context.state.login) {
                retLogin = true
                context.commit(PURGE_AUTH); 
            }                  
            resolve(retLogin)

          });            
    } 
    */
};

const mutations = {
    [SET_ALERT_RESPONSE](state, response) {
        state.dialog = true
        state.ok = "OK"

        if (response){
            if (response.status && response.status == 401) {
                state.login = true;
            }

            if (response.data && response.data.error && response.data.error.message) {
                state.message = response.data.error.message;  
               return
            }
            if (response.statusText) {
                state.message = response.statusText;  
                return
            }           
        } 
        state.message = "Unbekannter Fehler";

      },
      [SET_ALERT](state, message) {
        state.ok = "OK"
        state.dialog = true
        state.message = message;
      },      
      [SET_ALERT_LOGOUT](state, message) {
        state.ok = "OK"
        state.dialog = true
        state.login = true
        state.message = message;
      },      
      [SET_CONFIRM](state, confirm) {

        state.dialog = true
        state.message = confirm.message
        state.confirmLink = confirm.link
        state.confirmPageParam = confirm.pageParam
        

        if (confirm.ok) {
            state.ok = confirm.ok
        } else {
            state.ok = "OK"
        }
        if (confirm.cancle) {
            state.cancle = confirm.cancle
        } else {
            state.cancle = "Abbrechen"
        }
      },       
      [SET_ALERT_RESET](state) {
        state.dialog = false
        state.message = null
        state.login = null,
        state.confirmLink = null,
        state.confirmPageParam = null,
        state.ok = null,
        state.cancle = null
      }
};
export const alert =  {
    namespaced: false,
    state,
    actions,
    mutations
};

