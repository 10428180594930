import ApiService from "@/common/api.service";

import JwtService from "@/common/jwt.service";
import {
  LOGIN,
  LOGOUT,
  KENNWORT,
  CHECK_AUTH,
  KENNWORT_VERGESSEN,
  KENNWORT_ANFORDERN
} from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_ALERT_RESPONSE, FETCH_START, FETCH_END , SET_ALERT_LOGOUT} from "./mutations.type";
const state = {
    isAuthenticated: !!JwtService.getToken()
  };
  
  const getters = {
    currentUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  };
  



  const actions = {
    [LOGIN](context, credentials) {
      context.commit(FETCH_START);

      return new Promise((resolve, reject) => {
        ApiService.post("user", credentials)
          .then(({ data }) => {
            context.commit(FETCH_END);

            if (data.jwtToken) {
              context.commit(SET_AUTH, data.jwtToken);
              resolve(data);
              return
            }
            context.commit( SET_ALERT_RESPONSE, "Fehler beim Anmelden");  
            resolve(data)
            return
          })
          .catch(({ response }) => {
              context.commit(FETCH_END);
              context.commit( SET_ALERT_RESPONSE, response); 
              reject(response)
              return
          });
      });
    },
    [LOGOUT](context) {
      return new Promise((resolve) => {
        ApiService.delete("user")
          .then(() => {
            context.commit(PURGE_AUTH);  
            resolve()
          }).catch(() => {
            context.commit(PURGE_AUTH);  
            resolve()
          });
      });      
    },
    [KENNWORT](context, form) {
      context.commit(FETCH_START);
      let param = {
        pch1: form.old_password,
        pch2: form.password,
        pch3: form.reply_password,
        pfkt: 909
      }
 
   
      return new Promise((resolve, reject) => {

        ApiService.query("query", param)
          .then(({ data }) => {
            context.commit(FETCH_END);
            context.commit( SET_ALERT_LOGOUT, 'Kennwort wurde geändert! Sie werden automatisch abgemeldet!');  

            resolve(data)
            return
          })
          .catch(({ response }) => {
              context.commit(FETCH_END);
              context.commit( SET_ALERT_RESPONSE, response);  
              reject(response)
              return
          });
         
      });
      
    },    
    [KENNWORT_VERGESSEN](context, param) {
      context.commit(FETCH_START);

   
      return new Promise((resolve, reject) => {

        ApiService.query("forgot_password", param)
          .then(({ data }) => {
            context.commit(FETCH_END);
            context.commit( SET_ALERT_LOGOUT, 'Ihnen wurde eine E-Mail zur Wiederherstellung des Kennworts versendet!');  

            resolve(data)
            return
          })
          .catch(({ response }) => {
              context.commit(FETCH_END);
              context.commit( SET_ALERT_RESPONSE, response);  
              reject(response)
              return
          });
         
      });
      
    },    
    [KENNWORT_ANFORDERN](context, params) {
      context.commit(FETCH_START);
  
   
      return new Promise((resolve, reject) => {

        ApiService.post("new_password", params)
          .then(({ data }) => {
            context.commit(FETCH_END);
            context.commit( SET_ALERT_LOGOUT, 'Ihnen wurde eine E-Mail mit dem neuen Kennwort versendet!');  

            resolve(data)
            return
          })
          .catch(({ response }) => {
              context.commit(FETCH_END);
              context.commit( SET_ALERT_RESPONSE, response);  
              reject(response)
              return
          });
         
      });
      
    },     
    [CHECK_AUTH](context) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        /*
        return new Promise((resolve, reject) => {
          ApiService.get("user")
            .then(({ data }) => {
              if (data.jwtToken) {  
                context.commit(SET_AUTH, data.jwtToken);
                resolve()
                return
              }
              reject()
              return
            })
            .catch(({ response }) => {
              if (response.status === 404) {
                  context.commit(PURGE_AUTH);
                  reject(response)
                  return
               }
              resolve()
              return
            });
          });
          */
      } else {
        context.commit(PURGE_AUTH);
      }
    }
  };
  
  const mutations = {
    [SET_AUTH](state, token) {
      JwtService.saveToken(token);
      ApiService.setHeader();
      state.isAuthenticated = true;
    },
    [PURGE_AUTH](state) {
      ApiService.setHeader();
      state.isAuthenticated = false;
      JwtService.destroyToken();
    }
  };
  
  export default {
 

    state,
    actions,
    mutations,
    getters
  };
    