export const FETCH_END = "setArticles";
export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";

export const SET_ALERT_RESPONSE = "setAlertResonse";
export const SET_ALERT_RESET = "setAlertReset";
export const SET_ALERT = "setAlert";
export const SET_ALERT_LOGOUT = "setAlertLogoff";

export const SET_CONFIRM = "setConfirm";

export const RESET_STATE = "resetModuleState";

export const SET_DATEN = "setDaten";
export const RELAOD_ATTRIBUTE = "reloadAttribute";

export const SET_SELECTED_MENUE = "setSelectedMenue";

export const RESET_SEARCH = "resetDaten";


export const SET_SELEMENT_SVG_WIDTH = "setElmentSVGWidth";
export const SET_SELEMENT_SVG_HEIGHT = "setElementSVGHeight";