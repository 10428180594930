import Vue from "vue";
import Vuex from "vuex";

import home from "./home.module";
import auth from "./auth.module";

import { alert } from './alert.module';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

/*
import VueHighlightJS from 'vue-highlight.js';

import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/default.css';
Vue.use(VueHighlightJS, {
	// Register only languages that you want
	languages: {
		javascript,
	}
});
*/




Vue.use(Vuex);




export default new Vuex.Store({
  modules: {
    home,
    auth,
    alert,

  }
});